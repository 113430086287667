import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY, Subject, merge, of, timer } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { SmartApiProviderEnum } from '@simOn/common/providers';
import { DevicesApiService } from '@simOn/device/data-access';
import { DeviceProperties, DeviceStructure } from '@simOn/device/models';
import { DevicesFacade } from '../device/devices.facade';
import * as DevicePropertyActions from './device-property.actions';
import { LoadDevicePropertiesSuccess } from './device-property.actions';
const DEVICE_FETCH_TASK_INTERVAL = 30 * 1000; // 30s
const DEVICE_FETCH_TASK_INTERVAL_10_S = 10 * 1000; // 30s
@Injectable()
export class DevicePropertyEffects {
  private actions$ = inject(Actions);
  private readonly devicesApiService = inject(DevicesApiService);
  private readonly deviceFacade = inject(DevicesFacade);
  private readonly _destroySource: Subject<void> = new Subject<void>();

  loadDeviceProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicePropertyActions.LoadDeviceProperties),
      tap(() => {
        this._destroySource.next();
      }),
      switchMap(({ apartmentProviders }) =>
        timer(0, DEVICE_FETCH_TASK_INTERVAL).pipe(
          switchMap((e) => {
            const loadedProviders: SmartApiProviderEnum[] = [];

            if (apartmentProviders.length) {
              return merge(
                ...apartmentProviders.map(({ masterDeviceId, smartApiProvider }) =>
                  this.devicesApiService.getDevicesProperties({ masterDeviceId, smartApiProvider }).pipe(
                    map((data: DeviceProperties[]) => {
                      loadedProviders.push(smartApiProvider);
                      return DevicePropertyActions.LoadDevicePropertiesSuccess({
                        data,
                        allLoaded: loadedProviders.length === apartmentProviders.length
                      });
                    }),
                    catchError((error: HttpErrorResponse) => {
                      return of(
                        DevicePropertyActions.LoadDevicePropertiesFailure({
                          error: JSON.parse(JSON.stringify(error)),
                          masterDeviceId
                        })
                      );
                    })
                  )
                )
              );
            } else {
              return of(
                DevicePropertyActions.LoadDevicePropertiesSuccess({
                  data: [],
                  allLoaded: true
                })
              );
            }
          }),
          takeUntil(this._destroySource)
        )
      )
    )
  );

  updateDeviceProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicePropertyActions.ExecuteCommand),
      exhaustMap(({ data }) =>
        this.devicesApiService.executeCommand(data.command).pipe(
          take(1),
          map(() => DevicePropertyActions.ExecuteCommandSuccess({ data })),
          catchError((error) => of(DevicePropertyActions.ExecuteCommandFailure({ error })))
        )
      )
    )
  );

  executeCommandSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicePropertyActions.ExecuteCommandSuccess),
      exhaustMap(({ data }) => {
        if (data.command.command.command === 'TryWakeUpDevice') {
          return this.deviceFacade.getDeviceById$(data.command.deviceId).pipe(
            take(1),
            switchMap((device: DeviceStructure) =>
              this.devicesApiService
                .getDeviceProperties({
                  deviceId: device.simlabDeviceId,
                  masterDeviceId: device.masterDeviceId || '',
                  smartApiProvider: device.smartApiProvider
                })
                .pipe(
                  map((data: DeviceProperties[]) => LoadDevicePropertiesSuccess({ data, allLoaded: true })),
                  catchError((error) => of(DevicePropertyActions.ExecuteCommandFailure({ error })))
                )
            )
          );
        }
        return EMPTY;
      })
    )
  );

  clearState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicePropertyActions.ClearDevicePropertiesState),
        tap(() => this._destroySource.next())
      ),
    { dispatch: false }
  );
}
