/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AnimationEvent } from '@angular/animations';
import { Directionality } from '@angular/cdk/bidi';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  NgZone,
  OnDestroy,
  QueryList,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { Subject, filter, merge, startWith, take, takeUntil } from 'rxjs';
import { ContentMargin } from '../../models/content-margin';
import { DRAWER_CONTAINER } from '../../tokens/drawer-container.token';
import { DrawerComponent } from '../drawer/drawer.component';

@Component({
  selector: 'sim-drawer-container',
  templateUrl: './drawer-container.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DRAWER_CONTAINER,
      useExisting: DrawerContainerComponent
    }
  ],
  host: {
    class: 'ui-drawer-container'
  }
})
export class DrawerContainerComponent implements AfterContentInit, OnDestroy {
  private readonly ngZone = inject(NgZone);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly directionality = inject(Directionality, { optional: true });
  private _element = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  private readonly _destroySource = new Subject<void>();

  @ContentChildren(DrawerComponent, {
    descendants: true
  })
  _allDrawers!: QueryList<DrawerComponent>;
  _drawers = new QueryList<DrawerComponent>();

  /** The drawer child with the `start` position. */
  get start(): DrawerComponent | null {
    return this._start;
  }

  /** The drawer child with the `end` position. */
  get end(): DrawerComponent | null {
    return this._end;
  }

  /** The drawer at the start/end position, independent of direction. */
  private _start!: DrawerComponent | null;
  private _end!: DrawerComponent | null;

  /**
   * The drawer at the left/right. When direction changes, these will change as well.
   * They're used as aliases for the above to set the left/right style properly.
   * In LTR, _left == _start and _right == _end.
   * In RTL, _left == _end and _right == _start.
   */
  private _left!: DrawerComponent | null;
  private _right!: DrawerComponent | null;

  /**
   * Margins to be applied to the content. These are used to push / shrink the drawer content when a
   * drawer is open. We use margin rather than transform even for push mode because transform breaks
   * fixed position elements inside of the transformed element.
   */
  private _contentMargins: ContentMargin = {
    left: null,
    right: null
  };

  get contentMargins(): ContentMargin {
    return this._contentMargins;
  }

  readonly _contentMarginChanges = new Subject<ContentMargin>();

  ngAfterContentInit(): void {
    this._allDrawers.changes
      .pipe(startWith(this._allDrawers), takeUntil(this._destroySource))
      .subscribe((drawer: QueryList<DrawerComponent>) => {
        this._drawers.reset(drawer.filter((item) => !item.container || item.container === this));
        this._drawers.notifyOnChanges();
      });

    this._drawers.changes.pipe(startWith(null), takeUntil(this._destroySource)).subscribe(() => {
      this._validateDrawers();

      this._drawers.forEach((drawer: DrawerComponent) => {
        this._watchDrawerToggle(drawer);
        this._watchDrawerPosition(drawer);
        this._watchDrawerMode(drawer);
      });

      if (!this._drawers.length || this._isDrawerOpen(this._start) || this._isDrawerOpen(this._end)) {
        this._updateContentMargins();
      }

      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._drawers.destroy();
    this._contentMarginChanges.complete();

    this._destroySource.next();
    this._destroySource.complete();
  }

  private _validateDrawers(): void {
    this._start = this._end = null;

    //NOTE: we can attach 2 drawers to one container (start/end)
    this._drawers.forEach((drawer: DrawerComponent) => {
      if (drawer.position == 'right') {
        if (this._end != null) {
          this._throwMatDuplicatedDrawerError('right');
        }

        this._end = drawer;
      } else {
        if (this._start != null) {
          this._throwMatDuplicatedDrawerError('left');
        }

        this._start = drawer;
      }
    });

    this._right = this._left = null;

    // Detect if we're LTR or RTL.
    if (this.directionality && this.directionality.value === 'rtl') {
      this._left = this._end;
      this._right = this._start;
    } else {
      this._left = this._start;
      this._right = this._end;
    }
  }

  private _watchDrawerPosition(drawer: DrawerComponent): void {
    if (!drawer) {
      return;
    }

    // NOTE: We need to wait for the microtask queue to be empty before validating,
    // since both drawers may be swapping positions at the same time.
    drawer.positionChanged.pipe(takeUntil(this._drawers.changes), takeUntil(this._destroySource)).subscribe(() => {
      //NOTE: Microtask -> https://javascript.info/microtask-queue
      this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
        this._validateDrawers();
      });
    });
  }

  private _throwMatDuplicatedDrawerError(position: string): void {
    throw Error(`A drawer was already declared for 'position="${position}"'`);
  }

  /**
   * Recalculates and updates the inline styles for the content. Note that this should be used
   * sparingly, because it causes a reflow.
   */
  private _updateContentMargins() {
    // 1. For drawers in `over` mode, they don't affect the content.
    // 2. For drawers in `side` mode they should shrink the content. We do this by adding to the
    //    left margin (for left drawer) or right margin (for right the drawer).
    // 3. For drawers in `push` mode the should shift the content without resizing it. We do this by
    //    adding to the left or right margin and simultaneously subtracting the same amount of
    //    margin from the other side.

    let left: number | null = 0;
    let right: number | null = 0;

    if (this._left && this._left.opened) {
      if (this._left.mode == 'side') {
        left += this._left.width;
      } else if (this._left.mode == 'push') {
        const width = this._left.width;
        left += width;
        right -= width;
      }
    }

    if (this._right && this._right.opened) {
      if (this._right.mode == 'side') {
        right += this._right.width;
      } else if (this._right.mode == 'push') {
        const width = this._right.width;
        right += width;
        left -= width;
      }
    }

    // If either `right` or `left` is zero, don't set a style to the element. This
    // allows users to specify a custom size via CSS class in SSR scenarios where the
    // measured widths will always be zero. Note that we reset to `null` here, rather
    // than below, in order to ensure that the types in the `if` below are consistent.
    left = left || null;
    right = right || null;

    if (left !== this._contentMargins.left || right !== this._contentMargins.right) {
      this._contentMargins = { left, right };

      // Pull back into the NgZone since in some cases we could be outside. We need to be careful
      // to do it only when something changed, otherwise we can end up hitting the zone too often.
      this.ngZone.run(() => this._contentMarginChanges.next(this._contentMargins));
    }
  }

  private _isDrawerOpen(drawer: DrawerComponent | null): boolean {
    return drawer != null && drawer.opened;
  }

  /** Subscribes to changes in drawer mode so we can run change detection. */
  private _watchDrawerMode(drawer: DrawerComponent): void {
    if (drawer) {
      drawer._modeChanged.pipe(takeUntil(merge(this._drawers.changes, this._destroySource))).subscribe(() => {
        this._updateContentMargins();
        this.changeDetectorRef.markForCheck();
      });
    }
  }
  /** Toggles the 'mat-drawer-opened' class on the main 'mat-drawer-container' element. */
  private _setContainerClass(isAdd: boolean): void {
    const classList = this._element.nativeElement.classList;
    const className = 'drawer-container-has-open';

    if (isAdd) {
      classList.add(className);
    } else {
      classList.remove(className);
    }
  }
  /**
   * Subscribes to drawer events in order to set a class on the main container element when the
   * drawer is open and the backdrop is visible. This ensures any overflow on the container element
   * is properly hidden.
   */
  private _watchDrawerToggle(drawer: DrawerComponent): void {
    //TODO: add animation!!
    drawer._animationStarted
      .pipe(
        filter((event: AnimationEvent) => event.fromState !== event.toState),
        takeUntil(this._drawers.changes)
      )
      .subscribe((event: AnimationEvent) => {
        // Set the transition class on the container so that the animations occur. This should not
        // be set initially because animations should only be triggered via a change in state.
        if (event.toState !== 'open-instant') {
          this._element.nativeElement.classList.add('mat-drawer-transition');
        }

        this._updateContentMargins();
        this.changeDetectorRef.markForCheck();
      });

    if (drawer.mode !== 'side') {
      drawer.openedChange
        .pipe(takeUntil(this._drawers.changes))
        .subscribe(() => this._setContainerClass(drawer.opened));
    }
  }
}
