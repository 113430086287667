<ng-container
  *ngIf="{
    breakpoint: deviceName$ | async,
    toolbar: navigation$ | async,
    hideBottomToolbar: hideBottomToolbar$ | async
  } as templateData"
>
  <sim-sidenav-container>
    <sim-sidenav mode="over" #sidenavLeft position="left" class="left-menu">
      <ng-content></ng-content>
    </sim-sidenav>
    <sim-sidenav mode="over" #sidenavRight position="right" class="left-menu">
      <ng-template #outletRight="cdkPortalOutlet" [cdkPortalOutlet]="selectedPortal"></ng-template>
    </sim-sidenav>
    <sim-sidenav-content>
      <div
        [class]="templateData.breakpoint"
        class="sim-page-container"
        [class.toolbar-logo]="!templateData.toolbar?.navLinks"
      >
        <common-navbar class="navbar" *ngIf="!hideNavbar">
          <ng-container *ngIf="templateData.toolbar?.navLinks; else logo">
            <button id="sidenav-open" sim-icon-button class="navbar__sidenav" (click)="sidenav.toggle()">
              <sim-icon icon="hamburger" color="#707070" sizeType="large"></sim-icon>
            </button>
            <div class="navbar__title">
              <span
                [ngClass]="{ 'navbar__title--mobile': templateData.breakpoint === 'mobile' }"
                class="navbar__title--main"
                >{{
                  templateData.breakpoint === 'tablet'
                    ? templateData.toolbar?.shortTitle
                    : templateData.toolbar?.headerTitle
                }}</span
              >
              <span *ngIf="templateData.breakpoint !== 'mobile'" class="navbar__title--router-link">{{
                activeRouterLink
              }}</span>
            </div>
            <nav class="navbar__links">
              <ng-container *ngFor="let nav of templateData.toolbar?.navLinks; let i = index; trackBy: trackByPath"
                ><a
                  id="nav-bar-link-{{ i }}"
                  sim-button
                  [routerLink]="nav.path"
                  routerLinkActive="active-link"
                  class="nav-link"
                  [class.inactive-link]="activeRouterLink !== nav.name"
                  (isActiveChange)="changeActiveRouterLink($event, nav.label)"
                  [class.invisible-nav]="nav.isMobileInvisible"
                  [class.sim-disabled]="nav.hasPrivilege === false"
                  ><span class="navbar__links__container"
                    ><sim-icon
                      [icon]="nav.icon || 'lights'"
                      sizeType="medium"
                      [color]="activeRouterLink !== nav.name ? '#A3A3A3' : '#000000'"
                    ></sim-icon>
                    <span *ngIf="templateData.breakpoint === 'desktop'" class="navbar__links__container--link">{{
                      nav.label
                    }}</span></span
                  ></a
                ></ng-container
              >
            </nav>
          </ng-container>
          <div class="navbar__user">
            <user-online-list [lightMode]="false" />
            <user-info [lightMode]="false" />
            <sim-fullscreen [lightMode]="false" />
          </div>
        </common-navbar>
        <common-content
          class="content"
          [style.marginTop]="hideNavbar ? 0 : ''"
          [style]="templateData.hideBottomToolbar && { '--temp-toolbar-h': 0 }"
        >
          <router-outlet></router-outlet>
        </common-content>
      </div>
    </sim-sidenav-content>
  </sim-sidenav-container>
</ng-container>

<ng-template #logo>
  <img class="logo" src="assets/images/logo/Logo_simon.svg" />
</ng-template>
