import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UpdateUserRole, User, UserCreate, VerifyEmailResponseInterface } from '@simOn/user/element/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getAllUsersRoles(): Observable<User[]> {
    return this.http.get<User[]>(`${this._apiUrl}/Access/GetAllUsersRoles`);
  }

  getUserApartments(): Observable<string[]> {
    return this.http.get<string[]>(`${this._apiUrl}/Access/GetUserApartments`);
  }

  addUserToApartment(body: UserCreate): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Access/AddUserToApartment`, body);
  }

  updateUserRole(body: UpdateUserRole) {
    return this.http.put(`${this._apiUrl}/Access/UpdateUserRoles`, body);
  }

  removeUserFromApartment(userGuid: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Apartments/RemoveUserFromApartment?userGuid=${userGuid}`);
  }

  revokeUserRole(roleGuid: string, userGuid: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Access/RevokeUserRole?roleGuid=${roleGuid}&userGuid=${userGuid}`);
  }

  verifyEmailExist(email: string): Observable<VerifyEmailResponseInterface> {
    return this.http.get<VerifyEmailResponseInterface>(`${this._apiUrl}/Access/VerifyEmailExists?email=${email}`);
  }
}
