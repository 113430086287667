import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FullScreenService {
  private document = inject<Document>(DOCUMENT);
  private _isFullScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isFullScreen = this._isFullScreen$.asObservable().pipe(
    tap((data) => {
      if (data) {
        this.requestFullscreen();
      } else {
        this.exitFullscreen();
      }
    })
  );

  setIsFullScreen(state: boolean): void {
    this._isFullScreen$.next(state);
  }

  requestFullscreen(): void {
    const element: any = this.document.documentElement;
    if (element['fullscreenEnabled']) {
      element.requestFullscreen();
    } else if (element['webkitRequestFullscreen']) {
      element['webkitRequestFullscreen']();
    } else if (element['mozRequestFullscreen']) {
      element['mozRequestFullScreen']();
    } else if (element['msRequestFullscreen']) {
      element['msRequestFullscreen']();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  }

  exitFullscreen(): void {
    try {
      if (this.document.exitFullscreen && this.document['Active']) {
        this.document.exitFullscreen();
      } else if (this.document['webkitExitFullscreen']) {
        this.document['webkitExitFullscreen']();
      } else if (this.document['mozCancelFullScreen']) {
        this.document['mozFullScreen'] && this.document['mozCancelFullScreen']();
      } else if (this.document['msExitFullscreen']) {
        this.document['msExitFullscreen']();
      }
    } catch (e) {
      console.log('full screen error');
    }
  }
}
