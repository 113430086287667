import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit, inject } from '@angular/core';
import { BreakpointService } from '@simOn/ui/breakpoint';
import { map } from 'rxjs';
import { FullScreenService } from './full-screen.service';

let uniqueIdCounter = 0;
@Component({
  selector: 'sim-fullscreen',
  templateUrl: './sim-fullscreen.component.html',
  styleUrls: ['./sim-fullscreen.component.scss'],
  host: { '[id]': 'id' }
})
export class SimFullscreenComponent implements OnInit {
  public readonly fullScreenService = inject(FullScreenService);
  private readonly breakpointService = inject(BreakpointService);
  private readonly platform = inject(Platform);
  @Input() lightMode: boolean;
  @Input() id = `sim-fullscreen-${uniqueIdCounter++}`;

  isFullScreen: boolean;
  readonly isAndroidOrDesktop$ = this.breakpointService.deviceType$.pipe(
    map((deviceType) => deviceType === 'desktop' || this.platform.ANDROID)
  );

  ngOnInit(): void {
    this.fullScreenService.isFullScreen.subscribe((data) => {
      this.isFullScreen = data;
    });
  }

  fullScreen(): void {
    this.fullScreenService.setIsFullScreen(!this.isFullScreen);
  }
}
