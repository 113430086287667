import { importProvidersFrom, inject, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { PayUModule } from '@simlab/payu';
import { AssetFacade } from '@simOn/asset';
import { NavBarDataInterface, NavBarDataInterfaceI, SimSplitContainerComponent } from '@simOn/common/containers';
import { DevicesFacade, WidgetFacade } from '@simOn/device';
import { DEVICE_MANAGER_PATH } from '@simOn/device/widget/tokens';
import { RightSidenavLayoutComponent, SimPageTemplateComponent } from '@simOn/layout';
import { PrivilegesFacade } from '@simOn/privileges';
import { PublicLinkService } from '@simOn/public-link';
import { RoomFacade } from '@simOn/room';
import { SceneFacade } from '@simOn/scene';
import { SpaceFacade } from '@simOn/space';
import { SimSubscriptionControllerService } from '@simOn/subscription';
import { PayUConfig } from '@simOn/subscription/payment-method/tokens';
import { spaceLimitGuard } from '@simOn/subscription/space-limit/guards';
import { SubscriptionInfoService } from '@simOn/subscription/space-limit/services';
import { TicketFacade } from '@simOn/ticket';
import { UserPreferencesFacade } from '@simOn/user';
import { UsersFacade } from '@simOn/user/last-visited/state';
import { EventDispatcher } from '@simOn/utils/events';
import {
  autoLoginPartialRoutesGuard,
  AutoLoginPartialRoutesGuard,
  OidcSecurityService,
  UserDataResult
} from 'angular-auth-oidc-client';
import { combineLatest, iif, Observable, of, takeUntil, tap } from 'rxjs';
import { SimDeviceWarningModalService } from 'simon-application';
import { Environment } from '../environments';
import { DASHBOARD_PAGE_DATA } from './dashboard.const';
import { AuthGuard } from './main/modules/core/services/auth_old/auth.guard';
import { ApartmentAccessGuard } from './main/modules/core/services/guards/apartment-access.guard';
import { LayoutPageRouterOutletComponent } from './pages/layout-page-router/layout-page-router-outlet.component';
import { PROPERTY_SETTINGS_PAGE_DATA_WITH_PRIVILEGES } from './property-settings.const';
import { PROVIDERS_SETTINGS_PAGE_DATA_WITH_PRIVILEGES } from './providers-settings.const';
import { apartmentResolverFn } from './resolvers/apartment.resolver';
import { languageResolver } from './resolvers/language.resolver';
import { connectionsAccessGuardFn } from './shared/guards/connections-access.guard';

export const DeviceErrorListener = new InjectionToken<Observable<unknown>>('Device Error Listener');
export const appRoutes = (environment: Environment): Routes => [
  {
    path: 'public',
    loadChildren: () => import('public-link').then((m) => m.publicLinkRoutes)
  },
  { path: 'callback', loadChildren: () => import('callback').then((m) => m.callbackRoutes) },
  {
    path: 'fibaroAuth',
    loadChildren: () => import('fibaroAuth').then((m) => m.fibaroAuthRoutes)
  },
  {
    path: 'smartThingsAuth',
    loadChildren: () => import('smartThingsAuth').then((m) => m.smartThingsAuthRoutes)
  },
  {
    path: 'wiserHomeAuth',
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('wiserHomeAuth').then((m) => m.wiserHomeAuthRoutes)
  },
  {
    path: 'netxAuth',
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('netxAuth').then((m) => m.netxAuthRoutes)
  },
  {
    path: 'maximoAuth',
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('maximoAuth').then((m) => m.maximoAuthRoutes)
  },
  {
    path: 'SEAuth',
    loadChildren: () => import('SEAuth').then((m) => m.sEAuthRoutes)
  },
  {
    path: 'eula',
    loadChildren: () => import('eula').then((m) => m.eulaRoutes)
  },
  {
    path: 'no-access',
    loadChildren: () => import('no-access').then((m) => m.noAccessRoutes)
  },

  {
    path: 'autologin',
    loadChildren: () => import('autologin').then((m) => m.autologinRoutes)
  },
  { path: 'callback', loadChildren: () => import('callback').then((m) => m.callbackRoutes) },
  {
    path: 'callbackMatterport',
    loadChildren: () => import('callbackMatterport').then((m) => m.callbackMatterportRoutes)
  },
  {
    path: 'payment-summary',
    loadChildren: () => import('payment-summary').then((m) => m.paymentSummaryRoutes)
  },
  {
    path: 'permission-conflict',
    loadChildren: () => import('permission-conflict').then((c) => c.permissionConflictRoutes)
  },
  {
    path: '',
    component: SimPageTemplateComponent,
    children: [
      {
        path: 'split-view',
        component: SimSplitContainerComponent,
        children: [
          {
            path: '',
            outlet: 'sub',
            loadChildren: () => import('news').then((m) => m.newsRoutes)
          },
          {
            path: 'apartment-selection',
            loadChildren: () => import('space-selection').then((m) => m.spaceSelectionRoutes)
          },
          { path: '', redirectTo: 'apartment-selection', pathMatch: 'full' }
        ]
        // loadChildren: () => import('./pages/sim-start/sim-start.routing').then((m) => m.simStartroutes)
      },
      {
        path: 'property-creation',
        loadChildren: () => import('space-creator').then((m) => m.spaceCreatorRoutes),
        providers: [SubscriptionInfoService],
        canActivate: [AuthGuard, spaceLimitGuard]
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('subscription').then((m) => m.subscriptionRoutes),
        providers: [
          importProvidersFrom(
            PayUModule.forRoot({ ...environment.payment.payU, posId: environment.payment.payU.posId.EURO })
          ),
          SimSubscriptionControllerService,
          {
            provide: PayUConfig,
            useValue: environment.payment.payU
          }
        ],

        canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: 'split-view',
        pathMatch: 'full'
      }
    ],
    resolve: {
      language: languageResolver,
      loadApplication: () => {
        const apartmentFacade = inject(SpaceFacade);
        const usersFacade = inject(UsersFacade);
        const oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
        const userPreferencesFacade = inject(UserPreferencesFacade);
        return oidcSecurityService.userData$.pipe(
          tap((state: UserDataResult) => {
            apartmentFacade.getApartments(),
              userPreferencesFacade.loadUserPreferences(),
              usersFacade.setSelectedUserId(state.userData.sub);
          })
        );
      }
    },
    canActivate: [autoLoginPartialRoutesGuard, AuthGuard]
  },
  {
    path: 'space/:apartmentId',
    component: RightSidenavLayoutComponent,
    children: [
      {
        path: 'apartment',

        canActivate: [ApartmentAccessGuard],
        loadChildren: () => import('matterport-viewer').then((m) => m.matterportViewerRoutes)
      },
      {
        path: 'interior-designer',
        loadChildren: () => import('interior-designer').then((m) => m.interiorDesignerRoutes)
      },
      {
        path: 'property-settings',
        component: LayoutPageRouterOutletComponent,

        data: {
          tokens: [PrivilegesFacade],
          data: PROPERTY_SETTINGS_PAGE_DATA_WITH_PRIVILEGES
        } as NavBarDataInterfaceI<NavBarDataInterface>,
        children: [
          {
            path: 'general-informations',
            loadChildren: () => import('general-information').then((m) => m.generalInformationRoutes)
          },
          {
            path: 'rooms',
            loadChildren: () => import('rooms').then((m) => m.roomsRoutes)
          },
          {
            path: 'users',
            loadChildren: () => import('users').then((m) => m.usersRoutes)
          },
          {
            path: 'role-templates',
            loadChildren: () => import('role-templates').then((m) => m.roleTemplatesRoutes)
          },
          {
            path: 'activity-log',
            loadChildren: () => import('activity-log').then((m) => m.activityLogRoutes)
          },
          {
            path: 'importer',
            data: {
              hideBottomToolbar: true
            },
            children: [
              {
                path: 'tag',
                loadChildren: () => import('import-mattertag').then((m) => m.importMattertagRoutes)
              },
              {
                path: 'note',
                loadChildren: () => import('import-notes').then((m) => m.importNotesRoutes)
              }
            ]
          },
          { path: '', redirectTo: 'general-informations', pathMatch: 'full' }
        ]
      },
      {
        path: 'providers-settings',
        component: LayoutPageRouterOutletComponent,
        data: {
          tokens: [PrivilegesFacade],
          data: PROVIDERS_SETTINGS_PAGE_DATA_WITH_PRIVILEGES
        } as NavBarDataInterfaceI<NavBarDataInterface>,
        children: [
          {
            path: 'connections',
            loadChildren: () => import('connections').then((m) => m.connectionsRoutes),
            canActivate: [connectionsAccessGuardFn]
          },
          {
            path: 'import-new-device',
            loadChildren: () => import('import-device').then((m) => m.importDeviceRoutes),
            canActivate: [connectionsAccessGuardFn]
          },
          {
            path: 'devices-list',
            loadChildren: () => import('device-list').then((m) => m.deviceListRoutes),
            canActivate: [connectionsAccessGuardFn]
          },
          {
            path: '',
            redirectTo: 'connections',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'dashboard',
        component: LayoutPageRouterOutletComponent,
        data: {
          data: DASHBOARD_PAGE_DATA
        } satisfies NavBarDataInterfaceI<NavBarDataInterface>,
        canActivate: [AuthGuard],
        providers: [{ provide: DEVICE_MANAGER_PATH, useValue: '../../dashboard/devices/deviceManager' }],
        children: [
          {
            path: 'devices',
            loadChildren: () => import('devices').then((m) => m.deviceRoutes())
          },
          {
            path: 'assets',
            loadChildren: () => import('assets').then((m) => m.assetRoutes())
          },
          {
            path: 'scenes',
            loadChildren: () => import('scenes').then((m) => m.sceneRoutes)
          },
          {
            path: 'resources',
            loadComponent: () => import('resources').then((m) => m.ResourcesComponent),
            children: [
              {
                path: 'maximo-links',
                loadChildren: () => import('maximo-link').then((m) => m.maximoLinkRoutes)
              }
            ]
          },
          {
            path: '',
            redirectTo: 'devices',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: '',
        redirectTo: 'apartment',
        pathMatch: 'full'
      }
    ],
    providers: [
      SimDeviceWarningModalService,
      {
        provide: DeviceErrorListener,
        deps: [WidgetFacade, SimDeviceWarningModalService, RoomFacade],
        useFactory: (
          widgetFacade: WidgetFacade,
          simDeviceWarningModalService: SimDeviceWarningModalService,
          roomFacade: RoomFacade
        ) => {
          return combineLatest([widgetFacade.checkDevicesAlarmStatus$, roomFacade.rooms$]).pipe(
            tap(([{ devices }, rooms]) => simDeviceWarningModalService.filterDevicesAndOpenModal(devices, rooms)),
            takeUntil(widgetFacade.clearAction)
          );
        }
      }
      // {
      //   provide: EnterApartmentEvents,
      //   deps: [
      //     SpaceFacade,
      //     UsersFacade,
      //     PrivilegesFacade,
      //     RoomFacade,
      //     SceneFacade,
      //     AssetFacade,
      //     WidgetFacade,
      //     DevicesFacade,
      //     TicketFacade,
      //     SignalRService,
      //     Router,
      //     PublicLinkService
      //   ],
      //   useFactory: enterApartmentsEventFactory
      // }
    ],
    resolve: {
      apartmentResolver: apartmentResolverFn,
      spaceEvents: () => new EventDispatcher()
    },
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const apartmentId = route.params['id'] || route.params['apartmentId'];
        const spaceFacade = inject(SpaceFacade);
        const privilegesFacade = inject(PrivilegesFacade);
        spaceFacade.setSelectedId(apartmentId);
        privilegesFacade.loadPrivileges();

        const guard = inject(AutoLoginPartialRoutesGuard);
        const publicLink = inject(PublicLinkService);

        return iif(
          () => publicLink.isPublicLinkModeActive(),
          of(true),
          guard.canActivate(route, state).pipe(
            tap((canActive) => {
              canActive;
              if (!canActive) {
                const url = state.url;
                url && localStorage.setItem('redirectUri', url);
              }
            })
          )
        );
      },
      AuthGuard
    ],
    canDeactivate: [
      () => {
        const spaceFacade = inject(SpaceFacade);
        const devicesFacade = inject(DevicesFacade);
        const usersFacade = inject(UsersFacade);
        const widgetFacade = inject(WidgetFacade);
        const assetFacade = inject(AssetFacade);
        const sceneFacade = inject(SceneFacade);
        const roomFacade = inject(RoomFacade);
        const ticketFacade = inject(TicketFacade);
        const privilegesFacade = inject(PrivilegesFacade);
        spaceFacade.leaveApartment();
        devicesFacade.clearState();
        privilegesFacade.clearState();
        ticketFacade.clearState();
        roomFacade.clearState();
        sceneFacade.clearState();
        assetFacade.clearState();
        widgetFacade.clearState();
        usersFacade.clearState();
        spaceFacade.clearState();
      }
    ]
  },
  { path: 'unauthorized', redirectTo: '' },

  { path: '**', redirectTo: '', pathMatch: 'full' }
];
