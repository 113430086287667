import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider, inject } from '@angular/core';
import { ModalRef } from '../models/confirmation-modal-ref';
import { ModalConfig } from '../models/modal-config';
import { MODAL_DATA } from '../models/modal-data';
import { IModalRef } from '../models/modal-ref';

@Injectable()
export class ModalService {
  private readonly overlay = inject(Overlay);

  createModal<T, R, W>(component: ComponentType<T>, config: ModalConfig, data?: W): ModalRef<R> {
    const overlayRef = this.createOverlay(config);
    const confirmationModalRef = new ModalRef<R>(overlayRef);
    const injector = Injector.create({
      providers: [
        { provide: ModalRef, useValue: confirmationModalRef },
        { provide: MODAL_DATA, useValue: data }
      ]
    });

    const userProfilePortal = new ComponentPortal(component, null, injector);
    overlayRef.attach(userProfilePortal);

    return confirmationModalRef;
  }

  createModalWithProviders<T, R>(
    component: ComponentType<T>,
    config: ModalConfig,
    staticProviders: StaticProvider[] = []
  ): ModalRef<R> {
    const overlayRef = this.createOverlay(config);
    const confirmationModalRef = new ModalRef<R>(overlayRef);
    const injector = Injector.create({
      providers: [{ provide: ModalRef, useValue: confirmationModalRef }, ...staticProviders]
    });

    const userProfilePortal = new ComponentPortal(component, null, injector);
    overlayRef.attach(userProfilePortal);

    return confirmationModalRef;
  }

  create<T>(component: ComponentType<T>, config: ModalConfig): IModalRef<T> {
    const overlayRef = this.createOverlay(config);

    const userProfilePortal = new ComponentPortal(component);
    return {
      overlayRef: overlayRef,
      componentRef: overlayRef.attach(userProfilePortal)
    };
  }

  private createOverlay(config: ModalConfig): OverlayRef {
    const overlayConfig = this.createConfig(config);

    const overlayRef = this.overlay.create(overlayConfig);

    if (config.closeOnBackdropClick) {
      overlayRef.outsidePointerEvents().subscribe(() => {
        overlayRef.dispose();
      });
    }

    return overlayRef;
  }

  private createConfig(config: ModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global();

    if (config.centered || config.centered === undefined) {
      positionStrategy
        .centerHorizontally(coerceCssPixelValue(config.offset ? config.offset.x : 0))
        .centerVertically(coerceCssPixelValue(config.offset ? config.offset.y : 0));
    }
    config.bottom && positionStrategy.bottom(config.bottom);
    config.left && positionStrategy.left(config.left);
    config.right && positionStrategy.right(config.right);
    config.top && positionStrategy.top(config.top);

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop ?? true,
      backdropClass: config.backdropClass ?? 'ui-overlay-backdrop',
      panelClass: config.panelClass ?? 'ui-overlay-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy,
      maxHeight: config.maxHeight ?? 'min(100%,100vh)',
      minHeight: config.minHeight ?? 'auto',
      maxWidth: config.maxWidth ?? 'min(100%,100vw)',
      minWidth: config.minWidth ?? 'initial',
      height: config.height ?? 'auto',
      width: config.width ?? 'auto'
    });
  }
}
