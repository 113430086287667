import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApartmentInterface } from '@simOn/space/information/models';
import * as ApartmentReducer from './space.reducers';

export const SelectApartmentState = createFeatureSelector<ApartmentReducer.TSpaceState>('spaces');

export const SelectAllApartments = createSelector(SelectApartmentState, ApartmentReducer.selectAll);

export const Loading = createSelector(SelectApartmentState, (state) => state.isLoading || false);

export const SelectApartmentById = (apartmentId: string) =>
  createSelector(SelectApartmentState, (state) => state.entities[apartmentId]!);

export const GetSelectedApartmentId = createSelector(SelectApartmentState, (state) => state.selectedId);
export const GetSelectedApartment = createSelector(SelectApartmentState, (state) =>
  state.selectedId ? state.entities[state.selectedId] : undefined
);
export const GetSelectedMatterportModelId = createSelector(
  SelectApartmentState,
  (state) => state.selectedMatterportModelId
);
export const ApartmentHomeCenters = (apartmentId: string) =>
  createSelector(SelectApartmentById(apartmentId), (apartment: ApartmentInterface) => {
    return (apartment?.providerConnections || [])
      .filter((provider) => provider.masterDeviceId !== null)
      .map((provider) => provider.masterDeviceId);
  });
export const SelectSelectedApartment = createSelector(
  SelectAllApartments,
  GetSelectedApartmentId,
  (apartments: ApartmentInterface[], selectedApartmentId: string | undefined) => {
    return apartments.find((apartment: ApartmentInterface) => apartment.id === selectedApartmentId);
  }
);
// export const GetSelectedApartment = createSelector(SelectAllApartments, GetSelectedApartmentId, (apartments, id) => {
//   return apartments.find((apartment: ApartmentInterface) => apartment.id === id);
// });

export const GetApartmentProviders = createSelector(
  GetSelectedApartment,
  (apartment: ApartmentInterface | undefined) => {
    return apartment?.providerConnections || [];
  }
);
export const GetApartmentPhotoUrl = createSelector(
  GetSelectedApartment,
  (apartment: ApartmentInterface | undefined) => {
    return apartment?.photoUrl;
  }
);
export const DefaultMatterportModelId = createSelector(
  GetSelectedApartment,
  (apartment: ApartmentInterface | undefined) => {
    return apartment?.matterportModels?.find((model) => model.isDefault)?.id || apartment?.matterportModels?.[0].id;
  }
);
