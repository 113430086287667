import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
// imports for hammer
import { gitInfo } from '../git-info';

import { RouterActionsService } from './services/router-actions.service';

import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { FullScreenService } from '@simOn/ui/sim-fullscreen';
import { MatterportConfig } from '@simlab/matterport';
import { MatterportApiConfig, MatterportApiModule } from '@simlab/matterport/api';
import { providePayPalConfig } from '@simlab/payPal';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import {
  API_URL,
  AttachmentSizesToken,
  BASE_URL,
  DEMO_APARTMENTS,
  GIT_INFO,
  PAYU_REDIRECT,
  SIGNAL_R_API_URL
} from '@simOn/utils/tokens';
import { appRoutes } from './app.routes';
import { provideAuthConfig } from './auth/auth-config.config';
import { provideCoreConfig } from './main/modules/core/core.config';

import { SubscriptionApiService } from '@simOn/subscription/space-limit/data-access';

import { AssetEffects, AssetFacade, fromAsset } from '@simOn/asset';
import { SidenavService } from '@simOn/common/containers';
import {
  DevicePropertyEffects,
  DevicesEffects,
  DevicesFacade,
  fromDevices,
  fromDevicesProperty,
  fromWidget,
  WidgetEffects,
  WidgetFacade
} from '@simOn/device';
import { fromScene, SceneEffects, SceneFacade } from '@simOn/scene';
import { fromTicket, TicketEffects, TicketFacade } from '@simOn/ticket';

import { fromSpace, SpaceEffects, SpaceFacade } from '@simOn/space';
import { fromUserPreferences, UserPreferencesEffect, UserPreferencesFacade } from '@simOn/user';

import { MatteportAuthProviderApi, RedirectAfterLoginUrl } from '@simOn/matterport/auth/tokens';
import { fromPrivileges, PrivilegesEffects, PrivilegesFacade } from '@simOn/privileges';
import { ProvidersApiService } from '@simOn/provider/connection/data-access';
import { fromRoom, RoomEffects, RoomFacade } from '@simOn/room';
import { IdentityUrl } from '@simOn/user/auth/service';
import { fromUsers, UsersEffects, UsersFacade } from '@simOn/user/last-visited/state';
import { HubName, SignalRService } from '@simOn/utils/signalR';
import { environment } from '../environments';

export function getBaseUrl() {
  const baseAddress = document.getElementsByTagName('base')[0].href;
  return baseAddress.substring(0, baseAddress.length - 1);
}
export const appConfig: ApplicationConfig = {
  providers: [
    UsersFacade,
    SpaceFacade,
    DevicesFacade,
    WidgetFacade,
    UserPreferencesFacade,
    TicketFacade,
    SceneFacade,
    AssetFacade,
    PrivilegesFacade,
    RoomFacade,

    provideEffects(
      SpaceEffects,
      UsersEffects,
      UserPreferencesEffect,
      AssetEffects,
      WidgetEffects,
      DevicesEffects,
      DevicePropertyEffects,
      AssetEffects,
      TicketEffects,
      SceneEffects,

      PrivilegesEffects,
      RoomEffects
    ),
    provideAnimations(),
    provideRouter(
      appRoutes(environment),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always'
      }),
      withComponentInputBinding()
    ),
    //TO DO INSIDE DEVICE IT IS
    // provideStoreConfig(),
    provideStore(
      {
        [fromUsers.USERS_FEATURE_KEY]: fromUsers.usersReducer,
        [fromSpace.SPACE_FEATURE_KEY]: fromSpace.reducer,
        [fromWidget.widgetFeatureKey]: fromWidget.reducer,
        [fromDevices.deviceStructureFeatureKey]: fromDevices.reducer,
        [fromDevicesProperty.devicePropertyFeatureKey]: fromDevicesProperty.reducer,
        [fromAsset.assetFeatureKey]: fromAsset.reducer,
        [fromUserPreferences.USER_PREFERENCES_FEATURE_KEY]: fromUserPreferences.reducer,
        [fromScene.ScenesFeatureKey]: fromScene.reducer,
        [fromRoom.roomFeatureKey]: fromRoom.reducer,
        [fromTicket.ticketFeatureKey]: fromTicket.reducer,
        [fromPrivileges.privilegesFeatureKey]: fromPrivileges.reducer
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true
        }
      }
    ),
    provideAuthConfig({
      authority: environment.authApi,
      postLogoutRedirectUri: environment.authApi,
      clientId: 'Simlab.SmarthomeJS',
      scope: 'openid email profile Simlab.SmarthomeAPI offline_access', // 'openid profile ' + your scopes
      redirectUrl: window.location.origin + `/${$localize.locale}` + '/callback',
      responseType: 'code',
      postLoginRoute: '/split-view/apartment-selection',
      silentRenew: true,
      silentRenewTimeoutInSeconds: 20,

      refreshTokenRetryInSeconds: 20,
      tokenRefreshInSeconds: 20,
      useRefreshToken: true,
      logLevel: environment.authApi.includes('dev') ? LogLevel.Error : LogLevel.None
    } as OpenIdConfiguration),
    provideCoreConfig(environment),
    importProvidersFrom(MatterportApiModule),
    // payUProviders(),
    provideStoreDevtools({
      maxAge: 50, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true
    }),
    { provide: IdentityUrl, useValue: environment.authApi },
    { provide: RedirectAfterLoginUrl, useValue: environment.matterportAuth.redirectAfterLoginUrl },
    {
      provide: AttachmentSizesToken,
      useValue: { imgMaxSize: environment.config.imgMaxSize, docMaxSize: environment.config.docMaxSize }
    },
    { provide: MatteportAuthProviderApi, useExisting: ProvidersApiService },
    { provide: BASE_URL, useFactory: getBaseUrl },
    { provide: SIGNAL_R_API_URL, useValue: environment.simlabApiSignal },
    { provide: HubName, useValue: environment.singalHubs.SimonHub },
    SignalRService,
    {
      provide: API_URL,
      useValue: environment.simlabApi
    },
    RouterActionsService,
    SidenavService,
    {
      provide: GIT_INFO,
      useValue: gitInfo
    },
    {
      provide: AttachmentSizesToken,
      useValue: {
        imgMaxSize: environment.config.imgMaxSize,
        docMaxSize: environment.config.docMaxSize
      }
    },
    {
      provide: DEMO_APARTMENTS,
      useValue: environment.demoApartments
    },
    FullScreenService,
    SubscriptionApiService,
    providePayPalConfig({
      clientId: environment.payment.payPal.clientId
    }),
    {
      provide: PAYU_REDIRECT,
      useValue: environment.payment.payU.redirect
    },
    {
      provide: MatterportConfig,
      useFactory: () => {
        return {
          key: environment.matterport.sdkKey,
          hideFullscreen: true,
          hideHelp: true
        } as MatterportConfig;
      }
    },
    {
      provide: MatterportApiConfig,
      useFactory: () => {
        return {
          applicationKey: environment.matterportAuth.apiKey,
          clientId: environment.matterportAuth.clientId,
          redirectOrigin: `${window.location.origin}${environment.matterportAuth.redirectAfterLoginUrl}`,
          matterportModelApiUrl: environment.matterportAuth.apiGraphUrl,
          matterportShowcaseApiUrl: environment.matterportAuth.apiGraphUrl
        } as MatterportApiConfig;
      }
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
};

// function payUProviders() {
//   return importProvidersFrom(
//     PayUModule.forRoot({ ...environment.payment.payU, posId: environment.payment.payU.posId.EURO } as Configuration)
//   );
// }
