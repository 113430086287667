import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FibaroApiInterceptor } from '@simOn/provider/fibaro/interceptors';
import { ModalService } from '@simOn/ui/sim-modal';
import { NotificationService } from 'libs/shared/common/notification/src/notifications/notification.service';
import { ApartmentIdApiInterceptor, SimlabApiInterceptor } from 'simon-application';
import { AuthGuard } from './services/auth_old/auth.guard';
import { ApartmentAccessGuard } from './services/guards/apartment-access.guard';
import { DraftAccessGuard } from './services/guards/draft-access.guard';

export function provideCoreConfig(config: any): EnvironmentProviders {
  return makeEnvironmentProviders([
    AuthGuard,
    ApartmentAccessGuard,
    DraftAccessGuard,
    ModalService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: SimlabApiInterceptor,
      multi: true,
      deps: [NotificationService, Router]
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: FibaroApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApartmentIdApiInterceptor,
      multi: true,
      deps: [Store]
    }
  ]);
}
