import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterActionsService {
  public router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  get data(): any {
    return this.router.getCurrentNavigation().extras.state;
  }

  get ac(): ActivatedRoute {
    return this.activatedRoute;
  }
}

// future
export const routerLinks = {
  allNews: ''
};
