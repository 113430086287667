import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { ProviderErrorType } from '@simOn/common/http';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { MatterportOauthApiService } from '@simOn/matterport/auth/data-access';
import { TokenDescriptionModalOpener } from '@simOn/matterport/auth/modals';
import { OauthState } from '@simOn/matterport/auth/models';
import { MatteportAuthProviderApi, RedirectAfterLoginUrl } from '@simOn/matterport/auth/tokens';
import { AuthorizationResult, OpenScanConfig } from '@simOn/matterport/viewer/models';
import { LOAD_MATTERTAGS } from '@simOn/matterport/viewer/tokens';
import { ModalService } from '@simOn/ui/sim-modal';
import { CustomPhases, MatterportScanStatus, MatterportService } from '@simlab/matterport';
import { MatterportApiFacadeService, ModelAccess } from '@simlab/matterport/api';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  catchError,
  defer,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';

@Injectable()
export class MatterportOauthService implements OnDestroy {
  private readonly redirectAfterLoginUrl = inject(RedirectAfterLoginUrl);
  private readonly matterportApiFacadeService = inject(MatterportApiFacadeService);
  private readonly modalService = inject(ModalService);
  private readonly matterportUsersApiService = inject(MatterportOauthApiService);
  private readonly matterportManagerService = inject(MatterportService);
  private readonly providersApiService = inject(MatteportAuthProviderApi);
  private readonly _destroyed = new Subject<void>();
  private readonly _stateSource = new BehaviorSubject<OauthState>(OauthState.INITIAL);
  private readonly _loginApartmentAuthorization$: Observable<void> =
    this.providersApiService.loginApartmentAuthorization({
      smartApiProvider: SmartApiProviderEnum.MATTERPORT,
      masterDeviceId: null
    });
  public readonly loadMattertags = inject<boolean>(LOAD_MATTERTAGS, { optional: true });

  readonly oauthState$: Observable<OauthState> = this._stateSource.asObservable();
  readonly deleteToken: void = this.matterportApiFacadeService.deleteToken();
  matterportTokenDescription = '';
  isShowNotFoundScanDescription = false;
  readonly loginMatterport$ = (openHintModal: boolean) =>
    defer(() => this._openExternalAuth$(this._loginApartmentAuthorization$, openHintModal).pipe(map(() => true)));

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  readonly createAndOpenScan$ = (openScanConfig: OpenScanConfig): Observable<MatterportScanStatus> => {
    this._stateSource.next(OauthState.LOADING);
    this._destroyed.next();
    this.matterportManagerService.destroy();
    return this._internalLoad$(openScanConfig);
  };

  private readonly _getAuthToken$ = (scanId: string) => {
    const addToApartmentAuthorizations$: Observable<void> = this.providersApiService.addApartmentAuthorizations;
    const checkIfScanAvailable$: (scanId: string, apartmentAuthorization$?: Observable<void>) => Observable<boolean> = (
      scanId: string,
      apartmentAuthorization$?: Observable<void>
    ) =>
      this.matterportApiFacadeService.getScanVisibility$(scanId).pipe(
        map(() => true),
        catchError(() => {
          this._stateSource.next(OauthState.EXTERNAL);
          return this.providersApiService.logoutApartmentAuthorization.pipe(
            map(() => {
              this.isShowNotFoundScanDescription = true;
              return false;
            })
          );
        })
      );
    return iif(
      () => this.matterportApiFacadeService.checkTokenExist(),
      checkIfScanAvailable$(scanId),
      this.matterportUsersApiService.getMatterportApartmentAccessToken().pipe(
        map((response) => response.accessToken),
        tap((accessToken: string) => {
          this.matterportApiFacadeService.setMatterportToken(accessToken);
        }),
        switchMap(() => checkIfScanAvailable$(scanId, addToApartmentAuthorizations$)),
        catchError((response: HttpErrorResponse) => {
          this._stateSource.next(OauthState.EXTERNAL);

          if (response.error)
            if (
              response.error.ErrorCode === ProviderErrorType.SmartApiAuthorization ||
              response.error.ErrorCode === ProviderErrorType.SmartApiAuthorizationProviderNoAuthorization ||
              response.status === 404
            ) {
              console.log(response);
              return this._openExternalAuth$(addToApartmentAuthorizations$).pipe(
                switchMap(() => checkIfScanAvailable$(scanId))
              );
            }
          if (response.error.ErrorCode === ProviderErrorType.SmartApiAuthorizationProviderLoggedOut) {
            return this._openExternalAuth$(this._loginApartmentAuthorization$).pipe(
              switchMap(() => checkIfScanAvailable$(scanId))
            );
          }
          return of(false);
        })
      )
    );
  };

  private readonly _openExternalAuth$ = (apartmentAuthorization$: Observable<void>, openHintModal?: boolean) =>
    defer(() =>
      this.matterportUsersApiService.authorize$(`${window.location.origin}${this.redirectAfterLoginUrl}`).pipe(
        switchMap(() =>
          this.providersApiService.getProviderConnectionStatus(SmartApiProviderEnum.MATTERPORT, null).pipe(
            tap(
              (status) =>
                status.matterportTokenDescription &&
                (this.matterportTokenDescription = status.matterportTokenDescription)
            ),
            switchMap((status) => {
              if (status.status === 'LoggedIn') {
                return this.providersApiService.logoutApartmentAuthorization;
              }
              return of(status);
            }),
            catchError((e) => of(e))
          )
        ),
        switchMap(() =>
          this.matterportApiFacadeService.authorization$().pipe(
            take(1),
            switchMap((result: AuthorizationResult) => {
              if (result?.status === 'SUCCESS') {
                return openHintModal
                  ? this._setMatterportTokenWithHintModal$(result, apartmentAuthorization$)
                  : this._setMatterportToken$(result, apartmentAuthorization$, this.matterportTokenDescription);
              }
              return throwError(() => {
                this._stateSource.next(OauthState.ERROR_CLIENT);
                this._destroyed.next();
                this.matterportManagerService.destroy();
                return new Error('Auth closed by client!');
              });
            })
          )
        )
      )
    );

  private readonly _setMatterportTokenWithHintModal$ = (
    result: AuthorizationResult,
    apartmentAuthorization$: Observable<void>
  ) =>
    TokenDescriptionModalOpener.openMatterportTokenDescriptionModal(
      this.modalService,
      this.matterportTokenDescription
    ).pipe(
      switchMap((tokenDescription) =>
        tokenDescription ? this._setMatterportToken$(result, apartmentAuthorization$, tokenDescription) : of(undefined)
      )
    );

  private readonly _setMatterportToken$ = (
    result: AuthorizationResult,
    apartmentAuthorization$: Observable<void>,
    tokenDescription: string
  ) => {
    if (!result?.response?.authCode) {
      console.log('auth code is undefined');
      return of(undefined);
    }
    return this.matterportUsersApiService.updateToken$(result.response.authCode, tokenDescription).pipe(
      switchMap(() => apartmentAuthorization$),
      switchMap(() =>
        this.matterportUsersApiService.getMatterportApartmentAccessToken().pipe(
          map((response) => response.accessToken),
          switchMap((token: string) => this.matterportApiFacadeService.clearCache$().pipe(map(() => token))),
          tap((token: string) => {
            this.matterportApiFacadeService.setMatterportToken(token);
          })
        )
      )
    );
  };

  private _internalLoad$ = (openScanConfig: OpenScanConfig): Observable<MatterportScanStatus> =>
    this.matterportUsersApiService.getMatterportModelAccess$(openScanConfig.scanId).pipe(
      tap((access: ModelAccess) => {
        console.log(
          `%c${access}`,
          'background-color: black; color: lightgreen; padding: 2px; text-transform: Uppercase'
        );
      }),
      mergeMap((access: ModelAccess) => {
        switch (access) {
          case ModelAccess.Public:
            return this._openPublicScan$(openScanConfig).pipe(this._tapOauthState$(OauthState.ERROR_PUBLIC));

          case ModelAccess.Private:
            return this._openProtectedScan$(openScanConfig).pipe(this._tapOauthState$(OauthState.ERROR_PRIVATE));

          case ModelAccess.Protected:
            return this._openProtectedScan$(openScanConfig).pipe(this._tapOauthState$(OauthState.ERROR_PROTECTED));
        }

        this._stateSource.next(OauthState.ERROR);
        return throwError(() => new Error('Unknown model access!'));
      }),
      takeUntil(this._destroyed)
    );

  private readonly _openProtectedScan$ = (openScanConfig: OpenScanConfig): Observable<MatterportScanStatus> => {
    const { containerRef, scanId, offset } = openScanConfig;
    return this._getAuthToken$(scanId).pipe(
      mergeMap((canOpen: boolean) => {
        if (!canOpen) {
          this._stateSource.next(OauthState.ERROR_CLIENT);
          throw new Error('Cant load  scan');
        }
        return this.matterportManagerService.createAndOpenScan$(
          containerRef,
          scanId,
          offset,
          this.loadMattertags !== null && this.loadMattertags !== undefined ? this.loadMattertags : true,
          undefined,
          openScanConfig.createPortals
        );
      })
    );
  };

  private readonly _openPublicScan$ = (openScanConfig: OpenScanConfig): Observable<MatterportScanStatus> => {
    const { containerRef, scanId, offset } = openScanConfig;
    this.matterportApiFacadeService.deleteToken();
    return this.matterportManagerService.createAndOpenScan$(
      containerRef,
      scanId,
      offset,
      this.loadMattertags !== null && this.loadMattertags !== undefined ? this.loadMattertags : true,
      undefined,
      openScanConfig.createPortals
    );
  };

  private readonly _tapOauthState$ = (onErrorState: OauthState): MonoTypeOperatorFunction<CustomPhases> =>
    tap((phase: CustomPhases) => {
      if (phase === CustomPhases.ERROR) {
        this._destroyed.next();
        this.matterportManagerService.destroy();
        this._stateSource.next(onErrorState);
      } else {
        this._stateSource.next(OauthState.LOADED);
      }
    });
}
