import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PrivilegesApiService } from '@simOn/privileges/element/data-access';
import { UserFullAccess } from '@simOn/privileges/element/models';
import { PublicLinkService } from '@simOn/public-link';
import { MissingRoomPrivilegesModalComponent } from '@simOn/room';
import { ModalService } from '@simOn/ui/sim-modal';
import { UserApiService } from '@simOn/user/element/data-access';
import { Observable, catchError, defer, map, of, switchMap } from 'rxjs';

@Injectable()
export class ApartmentAccessGuard {
  private readonly _userApiService = inject(UserApiService);
  private readonly _privilegesApiService = inject(PrivilegesApiService);
  private readonly _publicLinkService = inject(PublicLinkService);
  private readonly _router = inject(Router);
  private readonly _modalService = inject(ModalService);

  private readonly _missingRoomPrivilegesModal$: Observable<boolean> = defer(() => {
    const modalRef = this._modalService.createModal<MissingRoomPrivilegesModalComponent, void, void>(
      MissingRoomPrivilegesModalComponent,
      {
        width: 'min(480px, 100%)',
        hasBackdrop: true,
        backdropClass: 'ui-modal-backdrop'
      }
    );
    return modalRef.events$.pipe(
      map(() => {
        this._router.navigate(['split-view/apartment-selection']);
        return false;
      })
    );
  });

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const apartmentId = activatedRoute.params['id'] || activatedRoute.params['apartmentId'];
    return this._privilegesApiService.getUserFullAccesses(apartmentId).pipe(
      switchMap((roomPrivileges: UserFullAccess) => {
        if (
          roomPrivileges.roomAccesses.length === 0 &&
          !roomPrivileges.isOwner &&
          !this._publicLinkService.isPublicLinkModeActive()
        ) {
          return this._missingRoomPrivilegesModal$;
        }
        return this._userApiService.getUserApartments().pipe(
          map((apartments: string[]) => {
            if (apartments.includes(apartmentId)) {
              return true;
            }
            this._router.navigate(['split-view/apartment-selection']).then();
            return false;
          }),
          catchError((error) => {
            console.log(error);
            if (this._publicLinkService.isPublicLinkModeActive()) {
              return of(true);
            }
            this._router.navigate(['split-view/apartment-selection']).then();
            return of(false);
          })
        );
      }),
      catchError((e) => {
        console.log(e);
        this._router.navigate(['split-view/apartment-selection']);
        return of(false);
      })
    );
  }
}
