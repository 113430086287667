import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SHORT_UNITS } from '@simOn/common/units';
import { CommandValuesType, LevelValueParameter, paramJSON } from '@simOn/device/models';
import { SimSliderModule } from '@simOn/ui/sim-slider';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimSliderModule],
  template: `<sim-slider
    id="level-slider"
    [min]="settings?.value?.min || 0"
    [max]="settings?.value?.max || 100"
    [step]="settings?.value?.step || 1"
    [unit]="propertyUnit"
    [showValue]="true"
    [valueSlider]="propertyValue"
    (emitSliderValue)="propertyValue = $event"
  ></sim-slider> `,
  styleUrls: ['./level.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-level'
  }
})
export class LevelComponent extends PropertyBase<'Temperature', number, LevelValueParameter> {
  valueToCommand(params: number): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: 'SetLevel',
      parameters: JSON.stringify(this.valueToParameter(params))
    };
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | LevelValueParameter;
  }): number {
    const paramToJson: LevelValueParameter = paramJSON(params.parameters);
    return paramToJson.level;
  }
  valueToParameter(level: number): LevelValueParameter {
    return { level };
  }

  get settings() {
    return this.propertyDefinition.settings;
  }
  set propertyValue(value: number) {
    if (!this.propertyDefinition) {
      console.error('Set property definition input');
      return;
    }
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value
      }
    };
    this.partialCommand$.emit(this.valueToCommand(value));
  }
  get propertyValue(): number {
    return this.propertyDefinition.values.value || 0;
  }

  get propertyUnit(): string {
    return SHORT_UNITS[this.propertyDefinition.unit];
  }
}
