import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ProblemType, ProviderErrorType, RETRY_ERROR_CODE, SimlabErrorDto } from '@simOn/common/http';
import { NotificationService } from '@simOn/common/notification';
import { ErrorMessages } from '@simOn/utils';

import { of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export class SimlabApiInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      retry({
        count: 1,
        delay: (error: any, retryCount: number) => {
          const simlabError = error.error as SimlabErrorDto;
          if (req.method !== 'GET' || !RETRY_ERROR_CODE.includes(simlabError.ErrorCode)) return throwError(() => error);
          return of(500);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          // errorMessage = `Error: ${error.error.message}`;
        } else {
          const simlabError = error.error as SimlabErrorDto;
          if (simlabError.ErrorCode === ProblemType.PublicLinkExecuteCommandNotAllowed && error.status === 731) {
            this.notificationService.openWarnSnackBar(ErrorMessages.PublicLinkExecuteCommandNotAllowed);
          }

          if (simlabError.ErrorCode === ProblemType.WeatherNotAvailable && error.status === 730) {
            this.notificationService.openWarnSnackBar(ErrorMessages.WeatherNotAvailable);
          }
          // server-side error
          if (error.status === 500) {
            if (
              simlabError.ErrorCode === ProblemType.ApartmentBlockedBySubscriptionLimit ||
              simlabError.ErrorCode === ProblemType.UserBlockedBySubscriptionLimit
            ) {
              this.router.navigate(['/no-access']);
            }
            if (simlabError.ErrorCode.toString() === ProviderErrorType.SmartApiProblem) {
              this.notificationService.openErrorSnackBar(ErrorMessages.SmartApiTimeout);
            }
            if (simlabError.ErrorCode === ProblemType.SystemMaintenance) {
              this.notificationService.openErrorSnackBar(ErrorMessages.SystemMaintenance);
            }

            if (
              simlabError.ErrorCode.toString() === ProviderErrorType.SmartApiAuthorization ||
              simlabError.ErrorCode.toString() === ProviderErrorType.SmartApiAuthorizationProviderLoggedOut ||
              simlabError.ErrorCode.toString() === ProviderErrorType.SmartApiAuthorizationProviderNoAuthorization
            ) {
              return throwError(() => error);
            }

            if (simlabError.ErrorCode === ProblemType.InsufficientPermissions) {
              this.notificationService.openErrorSnackBar(ErrorMessages[simlabError.ErrorCode]);
              return throwError(() => error.error);
            }
          }

          // error.error?.Messages && error.error?.Messages[0]
          //   ? (errorMessage = `${error.error.Messages[0]}`)
          //   : (errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`);
        }
        return throwError(() => error.error || error);
      })
    );
  }
}
