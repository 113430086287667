import { CdkPortalOutlet, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, InjectionToken, Signal, viewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SidenavComponent, SimSidenavModule } from '@simOn/ui/sim-sidenav';
export const RightSidenavLayout = new InjectionToken<RightSidenavLayoutComponent>('RightSidenavLayoutComponent');
export interface IRightSidenavLayout {
  readonly _sidenavRight: Signal<SidenavComponent>;
  readonly _outlet: Signal<CdkPortalOutlet>;
  selectedPortal: ComponentPortal<any> | undefined;
  get sidenavRight(): SidenavComponent;
  get outlet(): CdkPortalOutlet;
}
@Component({
  standalone: true,
  imports: [PortalModule, SimSidenavModule, RouterModule],
  template: `
    <sim-sidenav-container>
      <sim-sidenav mode="over" #sidenavRight position="right" class="right-menu">
        <ng-template #outlet="cdkPortalOutlet" [cdkPortalOutlet]="selectedPortal"></ng-template>
      </sim-sidenav>
      <sim-sidenav-content>
        <router-outlet></router-outlet>
      </sim-sidenav-content>
    </sim-sidenav-container>
  `,
  styleUrl: './right-sidenav-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: RightSidenavLayout,
      useExisting: RightSidenavLayoutComponent
    }
  ]
})
export class RightSidenavLayoutComponent implements IRightSidenavLayout {
  readonly _sidenavRight = viewChild.required<SidenavComponent>('sidenavRight');
  readonly _outlet = viewChild.required<CdkPortalOutlet>('outlet');
  selectedPortal: ComponentPortal<any> | undefined;

  get sidenavRight() {
    return this._sidenavRight();
  }

  get outlet() {
    return this._outlet();
  }

  close() {
    this.sidenavRight.opened = false;
  }
}
