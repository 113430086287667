<div class="sim-sidenav-wrapper animated">
  <div class="sim-sidenav-close">
    <button id="main-sidenav-close" sim-icon-button (click)="closeSidenav()">
      <sim-icon icon="close2" color="var(--ui-secondary-darker, #3f484d)"></sim-icon>
    </button>
  </div>
  <div class="sim-sidenav-logo">
    <img src="./assets/images/logo/Logo_simon.svg" />
  </div>
  <div class="sim-sidenav-apartment mobile-hide">
    <space-upload-image
      id="main-sidenav-upload-image"
      [imageUrl]="(apartmentPhotoUrl()! | image | async)!"
      [uploadDisabled]="true"
      [small]="true"
    ></space-upload-image>
  </div>
  <span class="apartment-name sim-text-center mobile-hide">{{ apartmentName() }}</span>
  <div class="sim-sidenav-menu-items scrollbar">
    <a class="sim-sidenav-menu-item" routerLink="../apartment" routerLinkActive="sim-sidenav-menu-item--active">
      <sim-icon icon="general_info"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_WALK_BUTTON:">3D Walk</div>
    </a>

    <a class="sim-sidenav-menu-item" routerLink="../dashboard" routerLinkActive="sim-sidenav-menu-item--active">
      <sim-icon icon="rooms"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_DASHBOARD">Dashboard</div>
    </a>

    <a
      class="sim-sidenav-menu-item"
      [class.sim-sidenav-menu-item--disabled]="providersDisabled()"
      routerLink="../providers-settings"
      routerLinkActive="sim-sidenav-menu-item--active"
    >
      <sim-icon icon="star_filled"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_PROVIDERS">Providers</div>
    </a>

    <a class="sim-sidenav-menu-item" routerLink="../property-settings" routerLinkActive="sim-sidenav-menu-item--active">
      <sim-icon icon="settings" [color]="['transparent', '#1f2022']"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_SPACE_SETTINGS">Space settings</div>
    </a>

    @if (interiorDesignerVisible()) {
    <a
      class="sim-sidenav-menu-item"
      [class.sim-sidenav-menu-item--disabled]="publicMode()"
      routerLink="../interior-designer"
      routerLinkActive="sim-sidenav-menu-item--active"
    >
      <sim-icon icon="model_browser"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_INTERIOR_EDITOR">Interior editor</div>
    </a>
    }

    <a class="sim-sidenav-menu-item" routerLink="split-view">
      <sim-icon icon="holiday_village"></sim-icon>
      <div class="sim-sidenav-menu-item--text" i18n="@@LEFT_PANEL_MENU_3D_BROWSE_SPACES">Browse spaces</div>
    </a>
  </div>
  <div class="sim-sidenav-footer">
    <div>
      <img [src]="AssetsImage.LogoSimlabBlack | imagePath" alt="" width="80" />
    </div>

    <div>
      @if(!publicMode()){
      <button
        i18n="@@GENERAL_LOG_OUT"
        id="main-sidenav-logout"
        class="log-out-btn"
        sim-flat-button
        simColor="accent"
        (click)="logout()"
      >
        Log Out
      </button>
      }
    </div>
  </div>
</div>
